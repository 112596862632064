import React, {useState} from "react";
import styled from "styled-components";
import {
    Collapse,
    Nav,
    Navbar as ReactstrapNavbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink as RsNavLink,
} from "reactstrap";
import path from "path";
import {screenPxs} from "./utils";

const Logo = () => (
    <StyledLogo
        alt="Inliner logo"
        src={path.join(process.env.PUBLIC_URL, "/logo-text.svg")}
    />
);

const StyledLogo = styled.img`
    height: 20px;
`;

const StyledNavbar = styled(ReactstrapNavbar)`
    width: 100%;
    top: 0px;

    @media (min-width: ${screenPxs.md}) {
        position: absolute;
    }
`;

const NavLink = styled(RsNavLink)`
    color: white !important;
    font-size: 1.25rem;
`;

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return (
        <StyledNavbar expand="md" dark>
            <NavbarBrand>
                <Logo />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar back>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink
                            target="_blank"
                            href="https://forms.gle/6Lx2cfQwJXqFVAHG9"
                        >
                            Feedback
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="mailto:harry@inliner.io">
                            Email Us
                        </NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </StyledNavbar>
    );
};

export default Navbar;
