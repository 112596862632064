import {useState, useEffect} from "react";
import ReactGA from "react-ga";

// can be used as `await sleep(500)`
export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const screenSizes = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
};

export const screenPxs = {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
};

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export const usePageview = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });
};
