import {Button, InputGroupAddon} from "reactstrap";
import styled from "styled-components";

const StyledButton = styled(Button)`
    border: 0;
    background-color: #5bc85c;
`;

const SubmitButton = () => (
    <InputGroupAddon addonType="append">
        <StyledButton color="success">Submit</StyledButton>
    </InputGroupAddon>
);

export default SubmitButton;
