import {InputGroup, Input} from "reactstrap";
import SubmitButton from "./SubmitButton";

const EmailForm = (props) => {
    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = new URLSearchParams();
        params.set("form-name", "signup");
        params.set("email", props.email);
        try {
            await fetch("/", {
                method: "POST",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                body: params.toString(),
            });
        } catch (error) {
            // eslint-disable-next-line
            console.log(error);
            return;
        }
        props.setSubmitted("email_submitted");
    };

    return (
        <form onSubmit={handleSubmit}>
            <InputGroup>
                <Input
                    placeholder="Email"
                    name="email"
                    type="email"
                    value={props.email}
                    onChange={(e) => props.setEmail(e.target.value)}
                />
                <SubmitButton />
            </InputGroup>
        </form>
    );
};

export default EmailForm;
