import {useState} from "react";
import EmailForm from "./EmailForm";
import OptionalForm from "./OptionalForm";

// TODO: use React Final Form
const ConditionalForm = () => {
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState("no_submission");

    if (submitted === "no_submission") {
        return (
            <EmailForm
                email={email}
                setEmail={setEmail}
                setSubmitted={setSubmitted}
            />
        );
    } else if (submitted === "email_submitted") {
        return <OptionalForm email={email} setSubmitted={setSubmitted} />;
    } else if (submitted === "all_submitted") {
        return "Thanks, keep an eye out for our email 😎";
    }
    // eslint-disable-next-line
    return null;
};

export default ConditionalForm;
