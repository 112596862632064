import {useState} from "react";
import styled from "styled-components";
import {InputGroup, Input} from "reactstrap";
import SubmitButton from "./SubmitButton";
import {screenSizes, useWindowSize} from "./utils";

const OptionalForm = (props) => {
    const {width} = useWindowSize();
    const [formData, setFormData] = useState({
        editors: "",
        languages: "",
        company: "",
        email: props.email,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = new URLSearchParams();
        params.set("form-name", "detailed-signup");
        params.set("email", formData.email);
        params.set("editors", formData.editors);
        params.set("languages", formData.languages);
        params.set("company", formData.company);

        try {
            await fetch("/", {
                method: "POST",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                body: params.toString(),
            });
        } catch (error) {
            // eslint-disable-next-line
            console.log(error);
            return;
        }

        props.setSubmitted("all_submitted");
    };

    // TODO: clean up
    if (width >= screenSizes.sm) {
        return (
            <form onSubmit={handleSubmit}>
                <InputGroup>
                    <Input
                        placeholder="Language(s)"
                        name="languages"
                        type="text"
                        value={formData.languages}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                languages: e.target.value,
                            })
                        }
                    />
                    <Input
                        placeholder="Editor(s)"
                        name="editors"
                        type="text"
                        value={formData.editors}
                        onChange={(e) =>
                            setFormData({...formData, editors: e.target.value})
                        }
                    />
                    <Input
                        placeholder="Company"
                        name="company"
                        type="text"
                        value={formData.company}
                        onChange={(e) =>
                            setFormData({...formData, company: e.target.value})
                        }
                    />

                    <SubmitButton />
                </InputGroup>
            </form>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <MobileInput
                placeholder="Language(s)"
                name="languages"
                type="text"
                value={formData.languages}
                onChange={(e) =>
                    setFormData({...formData, languages: e.target.value})
                }
            />
            <MobileInput
                placeholder="Editor(s)"
                name="editors"
                type="text"
                value={formData.editors}
                onChange={(e) =>
                    setFormData({...formData, editors: e.target.value})
                }
            />
            <MobileInput
                placeholder="Company"
                name="company"
                type="text"
                value={formData.company}
                onChange={(e) =>
                    setFormData({...formData, company: e.target.value})
                }
            />

            <SubmitButton />
        </form>
    );
};

const MobileInput = styled(Input)`
    margin-bottom: 5px;
`;

export default OptionalForm;
