import {useState} from "react";
import ReactGA from "react-ga";
import styled from "styled-components";
import {Col} from "reactstrap";
import path from "path";
import {sleep, screenPxs, usePageview} from "./utils";
import Navbar from "./Navbar";
import ConditionalForm from "./ConditionalForm";

ReactGA.initialize("UA-193684700-1");

const VERB_START_TIME = 9.75;
const SUBTITLE_START_TIME = 11.5;
const CURSOR_BLINK = 200;
let verbStarted = false;
let subtitleStarted = false;

function App() {
    usePageview();

    const initialVerb = {
        text: "Understand",
        highlight: false,
        cursor: false,
    };
    const initialSubtitle = {
        text: "Available on VSCode",
        highlight: false,
        cursor: false,
    };
    const [verb, setVerb] = useState(initialVerb);
    const [subtitle, setSubtitle] = useState(initialSubtitle);

    const changeVerb = async () => {
        const text = "Write";
        setVerb((prev) => ({...prev, cursor: true, highlight: true}));
        await sleep(500);
        setVerb({
            text: text[0],
            highlight: false,
            cursor: true,
        });
        for (let i = 1; i < text.length; i++) {
            await sleep(200);
            setVerb((prev) => ({...prev, text: prev.text + text[i]}));
        }
        for (let i = 0; i < 2; i++) {
            await sleep(CURSOR_BLINK);
            setVerb((prev) => ({...prev, cursor: false}));
            await sleep(CURSOR_BLINK);
            setVerb((prev) => ({...prev, cursor: true}));
        }
        setVerb((prev) => ({...prev, cursor: false}));
    };

    const changeSubtitle = async () => {
        const text = "Sign up for early access";
        setSubtitle((prev) => ({...prev, cursor: true, highlight: true}));
        await sleep(250);
        setSubtitle({
            text: text[0],
            highlight: false,
            cursor: true,
            started: true,
        });
        for (let i = 1; i < text.length; i++) {
            await sleep(85);
            setSubtitle((prev) => ({...prev, text: prev.text + text[i]}));
        }
        for (let i = 0; i < 2; i++) {
            await sleep(CURSOR_BLINK);
            setSubtitle((prev) => ({...prev, cursor: false}));
            await sleep(CURSOR_BLINK);
            setSubtitle((prev) => ({...prev, cursor: true}));
        }
        setSubtitle((prev) => ({...prev, cursor: false}));
    };

    const handleTimeUpdate = (e) => {
        const time = e.target.currentTime;
        if (time >= VERB_START_TIME && !verbStarted) {
            verbStarted = true;
            changeVerb();
        }
        if (time >= SUBTITLE_START_TIME && !subtitleStarted) {
            subtitleStarted = true;
            changeSubtitle();
        }
    };

    return (
        <Main>
            <Header>
                <Navbar />
                <Title>
                    <Highlight visible={verb.highlight}>{verb.text}</Highlight>
                    <Cursor visible={verb.cursor}>|</Cursor>code
                    <Cursor visible={false}>|</Cursor>fast.
                </Title>
                <Subtitle>
                    Inliner lets you pull in blocks of code
                    <br />
                    without switching files.
                </Subtitle>
                <Description>
                    <Highlight visible={subtitle.highlight}>
                        {subtitle.text}
                    </Highlight>
                    <Cursor visible={subtitle.cursor}>|</Cursor>
                </Description>

                <FormContainer>
                    <ConditionalForm />
                </FormContainer>

                <VideoContainer md={10} lg={8}>
                    <Video
                        src={path.join(process.env.PUBLIC_URL, "demo.mp4")}
                        autoPlay
                        muted
                        playsInline
                        onTimeUpdate={handleTimeUpdate}
                        loop
                    />
                </VideoContainer>
            </Header>
        </Main>
    );
}

const Main = styled.div`
    height: 100vh;
    overflow-x: hidden;
`;

const Header = styled.header`
    background: rgb(13, 36, 40);
    background: linear-gradient(
        49deg,
        rgba(13, 36, 40, 1) 0%,
        rgba(16, 105, 150, 1) 22%,
        rgba(17, 159, 184, 1) 71%,
        rgba(0, 147, 131, 1) 99%
    );
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    position: relative;
`;

const Title = styled.p`
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 16px;
    margin-top: 48px;

    @media (min-width: ${screenPxs.sm}) {
        font-size: 64px;
        margin-bottom: 20px;
    }
`;

const Subtitle = styled.p`
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 300;

    @media (min-width: ${screenPxs.sm}) {
        font-size: 24px;
        margin-bottom: 30px;
    }
`;

const Description = styled.h1`
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;

    @media (min-width: ${screenPxs.sm}) {
        font-size: 18px;
    }
`;

const FormContainer = styled.div`
    padding: 0px 15px;
`;

const VideoContainer = styled(Col)`
    margin: 20px 0px;

    @media (min-width: ${screenPxs.sm}) {
        margin: 30px 0px;
    }
`;

const Video = styled.video`
    border-radius: 8px;
    box-shadow: 0px 8px 24px rgba(17, 17, 26, 0.1),
        0px 16px 56px rgba(17, 17, 26, 0.1), 0px 24px 80px rgba(17, 17, 26, 0.1);
    max-width: 1200px;

    width: 800px;

    @media (min-width: ${screenPxs.sm}) {
        width: 100%;
    }
`;

const Cursor = styled.span`
    opacity: ${({visible}) => Number(visible)};
`;

const Highlight = styled.span`
    background-color: ${({visible}) => (visible ? "#3f82a1dd" : "#00000000")};
`;

export default App;
